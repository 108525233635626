import { FetchArgs, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { AppState, store } from './store';
import { tokenApi } from './token/api';
import {
  resetTokens,
  saveAccessToken,
  saveRefreshToken,
} from './token/reducer';
import { ENGLISH } from '../constants/index';
import { DEFAULT_CURRENCY } from '../constants/currencies';
import { config } from '../config';
import { resetCompanyDetails, resetCorporateCompany } from './company/reducer';
import { resetInventoryDetails } from './inventory/reducer';
import { resetStaffDetails } from './user/reducer';
import { resetTransactionDetails } from './transaction/reducer';

const { REACT_APP_LEONCO_API_URL, REACT_APP_TOKEN_API_ID } = config;

// @ts-expect-error error in the api
export const staggeredBaseQuery = retry(
  // @ts-expect-error error in the api
  async (args: string | FetchArgs, api, extraOptions) => {
    const result = await fetchBaseQuery({
      baseUrl: `${REACT_APP_LEONCO_API_URL}`,
      prepareHeaders: (headers, { getState }) => {
        const token =
          (getState() as AppState)?.tokenReducer?.accessToken ??
          REACT_APP_TOKEN_API_ID;

        const currencySelected =
          (getState() as AppState)?.global?.selectedCurrency?.base ??
          DEFAULT_CURRENCY;

        const language =
          (getState() as AppState)?.global?.language?.languageTag ?? ENGLISH;

        if (token) {
          headers.set('Accept', 'application/json');
          headers.set('Content-Type', 'application/json');
          headers.set('Cache-Control', 'no-cache');
          headers.set('x-authentication-context', 'admin-web');
          headers.set('Authorization', `Bearer ${token}`);
          headers.set('x-api-token', `Bearer ${token}`);
          headers.set('Content-Currency', currencySelected);
          headers.set('Content-Language', language);
          headers.set('Access-Control-Allow-Origin', '*');
        }

        return headers;
      },
      method: 'GET',
    })(args, api, extraOptions);

    if (result.error?.status === 401) {
      try {
        const state = store.getState() as AppState;
        const refreshToken = state.tokenReducer.refreshToken as string;

        // @ts-expect-error store.dispatch is not typed
        const refreshResult = await store.dispatch(
          tokenApi.endpoints.refreshToken.initiate({ refreshToken }),
        );

        // logout if refresh token is expired
        if (
          refreshResult?.error?.status === 401 ||
          refreshResult?.error?.status === 400
        ) {
          store.dispatch(resetCompanyDetails(false));
          store.dispatch(resetCorporateCompany(false));
          store.dispatch(resetStaffDetails(false));
          store.dispatch(resetInventoryDetails(false));
          store.dispatch(resetTransactionDetails(false));
          store.dispatch(resetTokens());

          window.location.reload();
          return console.log({ refreshResult });
        }

        if (refreshResult?.data) {
          store.dispatch(saveAccessToken(refreshResult.data.accessToken));
          store.dispatch(saveRefreshToken(refreshResult.data.refreshToken));

          // Retry the original request with new token
          const retryResult = await fetchBaseQuery({
            baseUrl: REACT_APP_LEONCO_API_URL,
            prepareHeaders: (headers, { getState }) => {
              const token =
                (getState() as AppState)?.tokenReducer?.accessToken ||
                REACT_APP_TOKEN_API_ID;

              const currencySelected =
                (getState() as AppState)?.global?.selectedCurrency?.base ||
                DEFAULT_CURRENCY;

              const language =
                (getState() as AppState)?.global?.language?.languageTag ||
                ENGLISH;

              if (token) {
                headers.set('Accept', 'application/json');
                headers.set('Content-Type', 'application/json');
                headers.set('Cache-Control', 'no-cache');
                headers.set('x-authentication-context', 'admin-web');
                headers.set('Authorization', `Bearer ${token}`);
                headers.set('x-api-token', `Bearer ${token}`);
                headers.set('Content-Currency', currencySelected);
                headers.set('Content-Language', language);
                headers.set('Access-Control-Allow-Origin', '*');
              }

              return headers;
            },
            method: 'GET',
          })(args, api, extraOptions);

          return retryResult;
        } else {
          throw new Error('Failed to refresh token');
        }
      } catch (refreshError) {
        console.error('Refresh token failed:', refreshError);
        throw refreshError;
      }
    }

    return result;
  },
  {
    maxRetries: 1,
  },
);
