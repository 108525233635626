import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ASSIGN_CORPORATE_CARD,
  FETCH_CORPORATE_INVENTORY,
  FETCH_INVENTORY,
} from '../../constants/routes';
import {
  AddInventory,
  IInventoryResponse,
  UpdateInventory,
} from '../../types/inventory';
import { IQuery } from '../../types/default';
import { ICompanyArgs } from '../../types/company';
import { staggeredBaseQuery } from '../base-query';
import {
  IAssignCorporateCard,
  IAssignCorporateCompanyCard,
} from '../../types/user';

export const inventoryApi = createApi({
  reducerPath: 'inventories',
  baseQuery: staggeredBaseQuery,
  endpoints: (builder) => ({
    fetchAllInventory: builder.query<IInventoryResponse, { params: IQuery }>({
      query: ({ params }) => ({
        url: FETCH_INVENTORY,
        params,
        method: 'GET',
      }),
    }),
    fetchCorporateInventory: builder.query<IInventoryResponse, ICompanyArgs>({
      query: ({ companyUUID, params }) => ({
        url: `${FETCH_CORPORATE_INVENTORY}${companyUUID ? `/${companyUUID}` : ''}`,
        params,
        method: 'GET',
      }),
    }),
    deleteInventory: builder.mutation<string, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `${FETCH_INVENTORY}/${uuid}`,
        method: 'DELETE',
      }),
    }),
    addInventory: builder.mutation<IInventoryResponse, AddInventory>({
      query: ({ ...patch }) => ({
        url: FETCH_INVENTORY,
        body: patch,
        method: 'POST',
      }),
    }),
    updateInventory: builder.mutation<IInventoryResponse, UpdateInventory>({
      query: ({ ...patch }) => ({
        url: `${FETCH_INVENTORY}/${patch.uuid}`,
        body: patch,
        method: 'PATCH',
      }),
    }),
    assignCorporateCard: builder.mutation<
      IInventoryResponse,
      IAssignCorporateCard
    >({
      query: ({ ...body }) => ({
        url: ASSIGN_CORPORATE_CARD,
        method: 'PATCH',
        body: body,
      }),
    }),
    assignCorporateCompanyCard: builder.mutation<
      IInventoryResponse,
      UpdateInventory
    >({
      query: ({ ...body }) => ({
        url: `${FETCH_INVENTORY}/${body?.uuid}`,
        method: 'PATCH',
        body: body,
      }),
    }),
  }),
});

export const {
  useFetchAllInventoryQuery,
  useFetchCorporateInventoryQuery,
  useAddInventoryMutation,
  useUpdateInventoryMutation,
  useDeleteInventoryMutation,
  useAssignCorporateCardMutation,
  useAssignCorporateCompanyCardMutation,
} = inventoryApi;
