import { createApi } from '@reduxjs/toolkit/query/react';
import { REFRESH_TOKEN } from '../../constants/routes';
import { IRefreshToken, IRefreshTokenResponse } from '../../types/user';
import { staggeredBaseQuery } from '../base-query';

// @ts-expect-error error in the api
export const tokenApi = createApi({
  reducerPath: 'tokenRefresh',
  baseQuery: staggeredBaseQuery,
  endpoints: (builder) => ({
    refreshToken: builder.query<IRefreshTokenResponse, IRefreshToken>({
      // @ts-expect-error error in the api
      query: ({ ...refreshToken }) => ({
        url: `${REFRESH_TOKEN}`,
        body: refreshToken,
        method: 'POST',
      }),
    }),
  }),
});

export const { useRefreshTokenQuery } = tokenApi;
